%button{
    margin-left: 15px;
    display: inline;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 2px;
    color: $body-bg-color;
}

@mixin custom-button-mixin($color-variable) {
    @extend %button;
    border: 1px solid transparent;
    background-color: $color-variable;

    &:hover{
        background-color: lighten($color-variable, 10%);
    }
  
    &:active{
        background-color: darken($color-variable, 10%)
    }
  
    &:disabled {
        background: $disabled-color;
        cursor: arrow;
    }
  }
  
  
.custom-button-green {
    @include custom-button-mixin(green);
  }

  
.custom-button {
    @include custom-button-mixin($primary-color);
  }

.custom-button-invert{
    @extend %button;
    border: 1px solid $primary-color;
    background-color: $body-bg-color;
    color: $primary-color;

    &:hover{
        color: lighten($primary-color, 10%);
        border-color: lighten($primary-color, 10%);
    }

    &:active{
        color: darken($primary-color-dark, 10%);
        border-color: darken($primary-color, 10%);
    }

    &:disabled {
        background: $disabled-color;
        cursor: arrow;
        color: $body-bg-color;
        border: 1px solid $disabled-color;
    }
}

.button-block{
    display: block;
}

.btn-small-text {
    font-size:11px;
    margin-top: 2px;
}

#app-root[theme=dark]{
    .custom-button{
        background-color: $primary-color-dark;
        color: $body-bg-color-dark;

        &:hover{
            background-color: lighten($primary-color-dark, 10%);
        }
    
        &:active{
            background-color: darken($primary-color-dark, 10%)
        }

        &:disabled {
            background-color: $disabled-color-dark;
            color: lighten($disabled-color-dark, 20%);
        }
    }
    
    .custom-button-invert{
        border: 1px solid $primary-color-dark;
        background-color: $body-bg-color-dark;
        color: $primary-color-dark;

        &:hover{
            color: lighten($primary-color-dark, 10%);
            border-color: lighten($primary-color-dark, 10%);
        }
    
        &:active{
            color: darken($primary-color-dark, 10%);
            border-color: darken($primary-color-dark, 10%);
        }
    }
}