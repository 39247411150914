@import "styles/common/variables";
@import "styles/controls/buttons";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "styles/vendor/bootstrap-4-utilities.min.css";
@import "styles/icons.scss";

/* You can add global styles to this file, and also import other style files */
body {
  margin: 0px;
  color: var(--bodyText);
  //Use segoe UI in all components
  font-family: 'Segoe UI' !important;
}

.chatui-header-text {
  font-weight: 600;
  font-size: 2.75rem;
  line-height: 2.75rem;
  text-align: center;
  gap: 0.5rem;
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue, Arial, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.chatui-center-logo {
  width: 50px;
  height: 50px;
}

.copilot-header {
  color: #289dc2;
}

.copilot-header-secondary {
  font-size: 1.5rem;
}

.copilot-header-img {
  width: 35px;
  height: 35px;
}

.copilot-header-img-secondary {
  width: 20px;
  height: 20px;
}

a {
  color: var(--link);
}

a:hover {
  color: var(--linkHovered);
  background-color: (--linkBackgroundHovered);
}

.sub-title {
  color: var(--summaryText);
}

.skin-purple-light .sidebar-menu>li:hover>a,
.skin-purple-light .sidebar-menu>li.active>a {
  background: var(--bodyBackground)
}

.critical-color {
  color: #ff0000;
}

.warning-color {
  color: #ff9104;
}

.success-color {
  color: #3da907;
}

.info-color {
  color: #3a9bc7;
}

.color-ghostwhite {
  color: #e0e0e0;
}

.info-modal {
  max-width: 560px !important;
  padding: 0px !important;
  min-height: 160px !important;
  position: fixed;
}

.wide-modal {
  max-width: 560px !important;
  padding: 0px !important;
  min-height: 160px !important;
}

.package-modal {
  max-width: 100% !important;
  max-height: 90%;
  overflow-y: scroll !important;
  background-color: var(--bodyBackground) !important;
}

.panel.with-nav-tabs .panel-heading.app-nav-bar {
  padding: 5px 5px 0 5px;
}

.panel.with-nav-tabs .nav-tabs {
  border-bottom: none;
}

.panel.with-nav-tabs .nav-justified {
  margin-bottom: -1px;
}

.nav>li>a {
  padding: 5px 15px;
}

/********************************************************************/
/*** BOOTSTRAP PRIMARY BUTTON ***/
.btn-primary {
  color: var(--primaryButtonText);
  background-color: var(--primaryButtonBackground);
  border-color: var(--primaryButtonBorder);
}

.btn-primary:hover {
  color: var(-primaryButtonTextHovered);
  background-color: var(--primaryButtonBackgroundHovered);
}

/********************************************************************/
/*** PANEL DEFAULT ***/
.with-nav-tabs.panel-default .nav-tabs>li>a {
  background-color: var(--bodyBackground);
}

.with-nav-tabs.panel-default .nav-tabs>li>a,
.with-nav-tabs.panel-default .nav-tabs>li>a:hover,
.with-nav-tabs.panel-default .nav-tabs>li>a:focus {
  color: rgb(49, 49, 49);
}

.with-nav-tabs.panel-default .nav-tabs>.open>a,
.with-nav-tabs.panel-default .nav-tabs>.open>a:hover,
.with-nav-tabs.panel-default .nav-tabs>.open>a:focus,
.with-nav-tabs.panel-default .nav-tabs>li>a:hover,
.with-nav-tabs.panel-default .nav-tabs>li>a:focus {
  color: rgb(37, 37, 37);
  background-color: var(--bodyBackground);
  border-color: transparent;
}

.with-nav-tabs.panel-default .nav-tabs>li.active>a,
.with-nav-tabs.panel-default .nav-tabs>li.active>a:hover,
.with-nav-tabs.panel-default .nav-tabs>li.active>a:focus {
  color: rgb(26, 26, 26);
  background-color: var(--bodyBackground);
  border-color: #ddd;
  border-bottom-color: transparent;
}

.with-nav-tabs.panel-default .nav-tabs>li.dropdown .dropdown-menu {
  background-color: var(--bodyBackground);
  border-color: #ddd;
}

.with-nav-tabs.panel-default .nav-tabs>li.dropdown .dropdown-menu>li>a {
  color: #777;
}

.with-nav-tabs.panel-default .nav-tabs>li.dropdown .dropdown-menu>li>a:hover,
.with-nav-tabs.panel-default .nav-tabs>li.dropdown .dropdown-menu>li>a:focus {
  background-color: var(--bodyBackground);
}

.with-nav-tabs.panel-default .nav-tabs>li.dropdown .dropdown-menu>.active>a,
.with-nav-tabs.panel-default .nav-tabs>li.dropdown .dropdown-menu>.active>a:hover,
.with-nav-tabs.panel-default .nav-tabs>li.dropdown .dropdown-menu>.active>a:focus {
  color: var(--bodyBackground);
  background-color: #555;
}

.cc-modal {
  max-width: 800px;
}

.skin-blue .wrapper {
  background-color: var(--bodyBackground)
}

.publish-dialog {
  min-width: 70vw !important;
  min-height: 70vh !important;
  background-color: var(--bodyBackground) !important;
}

.icm-template-permissions-dialog {
  min-width: 50vw !important;
  min-height: 20vh !important;
  background-color: var(--bodyBackground) !important;
}

/* highchart container configuration */
.highcharts-container {
  overflow: visible !important;
  width: 100% !important;
}

.highcharts-container svg {
  overflow: visible !important;
  width: 100% !important;
}

highcharts-chart {
  overflow: visible !important;
}

.highcharts-tooltip-container span {
  font-size: "12px";
  font-weight: "normal";
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif !important;
}

fab-command-bar .ms-SearchBox {
  border-width: 0px !important;
}

.ms-CommandBar {
  padding: 0px !important;
}


.ms-Button--commandBar:hover {
  background-color: rgba(85, 179, 255, 0.1) !important;
}

.ms-CommandBar,
.ms-Button--commandBar {
  background-color: var(--bodyBackground);
}

.ms-Button--commandBar:focus {
  border: 1px solid black !important;
}

.fab-command-bar-class {
  // border-bottom: 1px solid #D6D6D6 !important;
  // font-size: 12px !important;
  // padding: 0px !important;
  // margin-top: 16px;
  // height: 34px;
  border-bottom: 1px solid rgb(204, 204, 204) !important;
  font-size: 12px !important;
  height: 40px;
  background-color: var(--bodyBackground) !important;
}

.fab-command-bar-class .ms-CommandBar {
  height: 33px;
}

.content-under-header {
  position: relative;
  top: 50px;
  height: calc(100% - 50px);
}

//Fix for markdown color
code[class*=language-] {
  color: var(--bodyText) !important;
  background-color: var(--bodyBackground) !important;
}

pre {
  background-color: var(--bodyBackground) !important;
  color: var(--bodyText) !important;
}

code[class*=language-]>span.token.operator {
  color: var(--bodyText) !important;
}

code[class*=language-]>span.token.punctuation {
  color: var(--bodyText) !important;
}

//Fix fab-link no focus indicator
button.ms-Link:focus {
  border: 1px solid black !important;
}

// Adding these styles here are ngx-markdown removed all styling after version v6.3.0
// https://github.com/jfcere/ngx-markdown/releases/tag/v6.3.0
// Breaking Changes
// Please note that markdown component/directive no longer comes with default styling. This change affects table and blockquote HTML elements only but still, we suggest you review your pages.
// https://github.com/jfcere/ngx-markdown/pull/108


markdown,
.markdown {
  table {
    border-spacing: 0;
    border-collapse: collapse;
    margin-bottom: 16px;

    th,
    td {
      padding: 6px 13px;
      border: 1px solid #ddd;

      &[align="left"] {
        text-align: left;
      }

      &[align="center"] {
        text-align: center;
      }

      &[align="right"] {
        text-align: right;
      }
    }

    tr:nth-child(2n) {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  blockquote {
    padding: 0 1em;
    color: rgba(0, 0, 0, 0.535);
    border-left: 0.25em solid rgba(0, 0, 0, 0.11);
  }
}

//
// For all Mat Dialogs launched for Workflows
//
.matDialogClass .mat-dialog-container {
  background-color: var(--bodyBackground);
  color: var(--bodyText);
  border: 1px solid;
}

//
// For all message boxes launched for workflows
//

.swal2-html-container,
.swal2-footer {
  font-size: large !important;
}